import { createApp } from "vue";
import AppNotification from "../components/common/AppNotification.vue";

export default async function notify(message, type) {
  return new Promise(() => {
    const app = createApp(AppNotification, {
      message: message,
      type: type,
    });

    const wrapper = document.createElement("div");
    wrapper.setAttribute("id", "notification");
    app.mount(wrapper);
    document.body.appendChild(wrapper);
  }).catch((err) => {
    throw err;
  });
}
