import Axios from "axios";
import mock from "../mocks/helpDomainService";
import { fetchAuthSession } from "aws-amplify/auth";
import { uploadData } from "aws-amplify/storage";
import { ref } from "vue";

const axios = Axios.create({
  baseURL:
    process.env.VUE_APP_API_ENDPOINT || "http://127.0.0.1:3000/api/admin/v1",
});

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${(
    await fetchAuthSession()
  ).tokens.idToken.toString()}`;

  return config;
});

if (
  process.env.VUE_APP_USE_MOCK === undefined ||
  JSON.parse(process.env.VUE_APP_USE_MOCK)
) {
  mock.run(axios);
}

function mockPreview(domain_key) {
  if (
    process.env.VUE_APP_USE_MOCK === undefined ||
    JSON.parse(process.env.VUE_APP_USE_MOCK)
  ) {
    mock.run(axios, domain_key);
  }
}

export default function () {
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimeFormat =
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2}$)/;

  // ISO 8601 フォーマットをDateオブジェクトに変換する
  const dateTimeReviver = (key, value) => {
    if (
      typeof value === "string" &&
      key === "publish_date" &&
      dateFormat.test(value)
    ) {
      return new Date(value);
    } else if (
      typeof value === "string" &&
      key === "created_at" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value);
    }

    return value;
  };

  const getRequestFilename = () => {
    var date = new Date();

    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  // api : admin-v1-faq-domains-get
  async function getHelpDomains() {
    return axios.get("/faq/help-domains", {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
  }

  // api : admin-v1-faq-preview-get

  const helpdomainpreviews = ref([]);

  async function getHelpDomainPreview(domain_key) {
    const response = await axios.get(
      `/faq/help-domains/${domain_key}/preview`,
      {
        transformResponse: (data) => {
          return JSON.parse(data, dateTimeReviver);
        },
      }
    );
    helpdomainpreviews.value = response.data.data.previews;
  }

  // api : admin-v1-faq-domains-post
  function createHelpDomain(helpDomain) {
    return axios.post("/faq/help-domains", {
      data: helpDomain,
    });
  }

  // api : admin-v1-faq-domains-put
  function sortHelpDomain(helpDomains) {
    return axios.put("/faq/help-domains", {
      data: helpDomains,
    });
  }

  // api : admin-v1-faq-domain-detail-put
  function updateHelpDomain(domainKey, helpDomain) {
    return axios.put(`/faq/help-domains/${domainKey}`, {
      data: helpDomain,
    });
  }

  // s3 put
  async function publishHelpDomain(domainKey, action) {
    const filename = getRequestFilename();

    const options = {
      domain_key: domainKey,
      publish_type: action,
    };

    await uploadData({
      key: `workflow/request/faq/publish/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return true;
  }

  async function stopHelpDomain(domainKey) {
    const filename = getRequestFilename();
    const options = {
      domain_key: domainKey,
    };
    await uploadData({
      key: `workflow/request/faq/stop/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return true;
  }

  return {
    helpdomainpreviews,
    getHelpDomains,
    createHelpDomain,
    sortHelpDomain,
    updateHelpDomain,
    publishHelpDomain,
    stopHelpDomain,
    getHelpDomainPreview,
    mockPreview,
  };
}
