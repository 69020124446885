<template>
  <div
    class="
      flex flex-col
      items-center
      justify-center
      fixed
      top-0
      left-0
      right-0
      bottom-0
      text-base
      overflow-hidden
    "
    :class="{ fadeout: !isLoading }"
  >
    <img :src="logo" />
    <br />
    Loading
  </div>
</template>

<script>
import Logo from "../../assets/logo-blue.webp";

export default {
  name: "LoadingScreen",
  props: ["isLoading"],
  computed: {
    logo() {
      return Logo;
    },
  },
};
</script>

<style>
.fadeout {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
