import Axios from "axios";
import mock from "../mocks/explodedViewService";
import { ref } from "vue";
import { uploadData } from "aws-amplify/storage";
import { fetchAuthSession } from "aws-amplify/auth";

const axios = Axios.create({
  baseURL:
    process.env.VUE_APP_API_ENDPOINT || "http://127.0.0.1:3000/api/admin/v1",
});

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${(
    await fetchAuthSession()
  ).tokens.idToken.toString()}`;

  return config;
});

if (
  process.env.VUE_APP_USE_MOCK === undefined ||
  JSON.parse(process.env.VUE_APP_USE_MOCK)
) {
  mock.run(axios);
}

function mockPreview(doc_no) {
  if (
    process.env.VUE_APP_USE_MOCK === undefined ||
    JSON.parse(process.env.VUE_APP_USE_MOCK)
  ) {
    mock.run(axios, doc_no);
  }
}

export default function () {
  const revisions = ref([]);
  const models = ref([]);

  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimeFormat =
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2}$)/;

  // ISO 8601 フォーマットをDateオブジェクトに変換する
  const dateTimeReviver = (key, value) => {
    if (
      typeof value === "string" &&
      (key === "publish_date" || key === "planned_publish_date") &&
      dateFormat.test(value)
    ) {
      return new Date(value);
    } else if (
      typeof value === "string" &&
      key === "approve_date" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value);
    } else if (
      typeof value === "string" &&
      key === "created_at" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value);
    }

    return value;
  };

  // リクエストファイルの名前を取得する
  const getRequestFilename = () => {
    var date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  // アップロードフォルダの名前を取得する
  const getUploadFoldername = () => {
    const date = new Date();
    return (
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  // api : admin-v1-ev-get
  async function getExplodedViews() {
    return axios.get("/ev/docs", {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
  }

  // api : admin-v1-ev-detail-get
  async function getExplodedView(doc_no) {
    const response = await axios.get(`/ev/docs/${doc_no}`, {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
    revisions.value = response.data.data.revisions;
  }

  // api : admin-v1-ev-detail-put
  async function changeExplodedView(doc_no, revision, data) {
    if (revision === null || revision === "") {
      revision = "----";
    }

    return await axios
      .put(`/ev/docs/${doc_no}/${revision}`, {
        data: data,
        transformResponse: (data) => {
          return JSON.parse(data, dateTimeReviver);
        },
      })
      .then((response) => {
        revisions.value = response.data.data.revisions;
        return {
          success: response.data.success,
          message: response.data.meta.messege,
        };
      })
      .catch((error) => {
        return {
          success: error.response.data.success,
          message: error.response.data.meta.message,
        };
      });
  }

  // s3 put event : import workflow
  async function uploadExplodedView(document, contentType, files, fileIndex) {
    const foldername = getUploadFoldername();
    let filenames = [];

    for (let [index, file] of files.entries()) {
      const filename = `upload/ev/capture/${foldername}/${document.number}/${file.name}`;

      switch (fileIndex[index]) {
        case "pdf":
          document.pdf_file_name = `public/${filename}`;
          break;
        case "image":
          document.image_file_name = `public/${filename}`;
          break;
        case "bom":
          document.bom_file_name = `public/${filename}`;
          break;
        case "hotspot":
          document.hotspot_file_name = `public/${filename}`;
          break;
      }

      try {
        await uploadData({
          key: filename,
          data: file,
          options: {
            accessLevel: "public",
          },
        }).result;
      } catch (error) {
        return {
          success: false,
          meta: {
            message: "アップロードに失敗しました",
          },
        };
      }
    }

    const documentfilename = `upload/ev/capture/${foldername}/${document.number}/document.json`;
    filenames.push(`public/${documentfilename}`);

    try {
      await uploadData({
        key: documentfilename,
        data: JSON.stringify(document),
        options: {
          accessLevel: "public",
        },
      }).result;
    } catch (error) {
      return {
        success: false,
        meta: {
          message: "アップロードに失敗しました",
        },
      };
    }

    await importExplodedView(filenames, contentType).catch(() => {
      return {
        success: false,
        meta: {
          message: "リクエストに失敗しました",
        },
      };
    });

    return {
      success: true,
    };
  }

  // private function
  async function importExplodedView(filenames, content_type) {
    const filename = getRequestFilename();

    const options = {
      content_type: content_type,
      upload_file: filenames,
    };

    await uploadData({
      key: `workflow/request/ev/capture/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "アップロードに成功しました",
      },
    };
  }

  // s3 put event : publish workflow
  async function publishExplodedView(doc_no, revision, publish_type) {
    const filename = getRequestFilename();

    const options = {
      doc_no: doc_no,
      revision: revision,
      publish_type: publish_type,
    };

    await uploadData({
      key: `workflow/request/ev/publish/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "公開をリクエストしました",
      },
    };
  }

  // s3 put event : stop workflow
  async function stopExplodedView(doc_no, revision) {
    const filename = getRequestFilename();

    const options = {
      doc_no: doc_no,
      revision: revision,
    };

    await uploadData({
      key: `workflow/request/ev/stop/${filename}.json`,
      data: JSON.stringify(options),
      options: {
        accessLevel: "public",
      },
    }).result;

    return {
      success: true,
      meta: {
        message: "公開停止をリクエストしました",
      },
    };
  }

  // api : admin-v1-ev-model-get
  async function getModels(doc_no, revision) {
    if (revision === null || revision === "") {
      revision = "----";
    }
    const response = await axios.get(`/ev/doc_model/${doc_no}/${revision}`);
    models.value = response.data.data.manual_model;
  }

  // api : admin-v1-ev-model-post
  async function postExplodedViewModel(doc_no, revision, data) {
    if (revision === null || revision === "") {
      revision = "----";
    }

    return await axios
      .post(`/ev/doc_model/${doc_no}/${revision}`, {
        data: data,
      })
      .then((response) => {
        models.value = response.data.data.manual_model;
        return {
          success: response.data.success,
          message: response.data.meta.messege,
        };
      })
      .catch((error) => {
        return {
          success: error.response.data.success,
          message: error.response.data.meta.message,
        };
      });
  }

  const explodedpreviews = ref([]);

  // api :admin-v1-ev-preview-get
  async function getExplodedPreview(doc_no) {
    const response = await axios.get(`/ev/docs/${doc_no}/preview`, {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
    explodedpreviews.value = response.data.data.previews;
  }

  return {
    revisions,
    models,
    explodedpreviews,
    getExplodedViews,
    getExplodedView,
    changeExplodedView,
    uploadExplodedView,
    publishExplodedView,
    stopExplodedView,
    getModels,
    postExplodedViewModel,
    getExplodedPreview,
    mockPreview,
  };
}
