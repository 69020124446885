<template>
  <LoadingScreen :isLoading="isLoading" />
  <div v-if="!isLoading">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Roboto", "Noto Sans JP", sans-serif !important;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  overflow-y: hidden;
}
</style>

<script>
import LoadingScreen from "./components/common/LoadingScreen.vue";

export default {
  components: {
    LoadingScreen,
  },
  computed: {
    isSignedIn() {
      return this.$store.state.user !== null;
    },
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>
