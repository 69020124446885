import { ref } from "vue";

const LoadingPlugin = {
  install: (app) => {
    const $loading = ref(false);

    app.provide("$loading", $loading);
  },
};

export default LoadingPlugin;
