import MockAdapter from "axios-mock-adapter";

export default {
  run: (axios, doc_no) => {
    const mock = new MockAdapter(axios);
    mock.onGet("/evs").reply(200, _ev);
    mock.onGet(`/ev/docs/${doc_no}/preview`).reply(200, _ev_preview);
  },
};

const _ev = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data: [
    {
      type: "ev",
      no: "bl-m7000-3979",
      name: "...",
      title: "",
      status: "公開中",
      publish_date: "2017-09-18",
      next_publish_date: "",
      next_revision_status: "",
    },
    {
      type: "ev",
      no: "bl-m7100-4595",
      name: "...",
      title: "",
      status: "公開中",
      publish_date: "2019-05-08",
      next_publish_date: "",
      next_revision_status: "",
    },
    {
      type: "ev",
      no: "bl-m8100-4504",
      name: "...",
      title: "",
      status: "公開中",
      publish_date: "2019-05-08",
      next_publish_date: "",
      next_revision_status: "",
    },
    {
      type: "ev",
      no: "bl-m9100-4371",
      name: "...",
      title: "",
      status: "公開中",
      publish_date: "2019-05-08",
      next_publish_date: "",
      next_revision_status: "インポート済み",
    },
    {
      type: "ev",
      no: "bl-mt201-4347",
      name: "...",
      title: "",
      status: "公開中",
      publish_date: "2019-05-08",
      next_publish_date: "",
      next_revision_status: "",
    },
  ],
});

const _ev_preview = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK"
  },
  data: {
    previews: [
      {
        revision: "",
        pdf_file_path: "EV-BR-R9110-F-4070.pdf",
        created_at: "2021-11-10T09:00:00+09:00"
      }
    ]
  }
});
