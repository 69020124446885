import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Tailwind CSS
import "./index.css";

// wijmo css
import "@grapecity/wijmo.styles/wijmo-core.css";
import "@grapecity/wijmo.cultures/wijmo.culture.ja";
import * as wjcCore from "@grapecity/wijmo";
wjcCore.setLicenseKey(process.env.VUE_APP_WIJMO_KEY);

// WindowSizePlugin & Loading
import WindowSizePlugin from "./plugins/window";
import LoadingPlugin from "./plugins/loading";

// ClickOutSide utility
import ClickOutside from "./api/clickOutside";

// Amplify
import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import awsconfig from "./aws-exports";
Amplify.configure({
  ...awsconfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

const app = createApp(App)
  .directive("click-outside", ClickOutside)
  .use(store)
  .use(router)
  .use(WindowSizePlugin)
  .use(LoadingPlugin);

app.mount("#app");

app.config.globalProperties.$store = store;
