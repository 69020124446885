<template>
  <!-- Title -->
  <app-aside-title @onClick="cancel">問い合わせ先の変更 </app-aside-title>

  <form @submit.prevent="onSubmitSave" class="mx-2 py-1.5">
    <div class="px-2 py-2">
      <label for="id" class="block font-medium text-sm text-gray-700">
        メールアドレス
      </label>
      <input
        type="text"
        id="mail_address"
        name="mail_address"
        v-model="form.mail_address"
        class="
          p-2
          border-solid
          mt-1
          block
          w-fulltext-base
          border border-gray-300
          rounded-md
          bg-white
          focus:ring-indigo-500 focus:border-indigo-500
          w-full
        "
      />
    </div>

    <!-- Action -->
    <div class="p-4">
      <div class="space-x-2">
        <app-button @onClick="onClickCancel">
          <template v-slot:label> 閉じる </template>
        </app-button>

        <app-button :type="'submit'">
          <template v-slot:label> 保存する </template>
        </app-button>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, reactive, onMounted } from "vue";
import AppAsideTitle from "../layout/AppAsideTitle.vue";
import AppButton from "../common/AppButton.vue";
import confirm from "../../api/confirm";
import notify from "../../api/notify";

// app
import ContactsService from "../../services/contactsService";

export default defineComponent({
  name: "ContactAdminChange",
  components: {
    AppAsideTitle,
    AppButton,
  },
  props: {
    mode: { type: String },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["cancel", "save"],
  setup(props, { emit }) {
    // form value
    const form = reactive({});

    const {
      currentContactRef,
      currentContacts,
      postContacts,
    } = ContactsService()

    onMounted(async () => {
      await currentContacts();
      await setValue();
    });

    // 編集データの準備
    const setValue = async () => {
      form.mail_address = currentContactRef.value;
    };

    // 編集内容を保存する
    const onSubmitSave = async () => {
      const result = await confirm(
        "更新処理を継続してよいですか?",
        `"${form.mail_address}で更新します."`
      );
      if (result === true) {
        const res = await postContacts([form.mail_address]);
        if (res.success) {
          form.mail_address = res.data.email[0];
          emit("save");
        } else {
          await notify(res.message, 2);
        }
      }
    };

    // 編集内容をキャンセルする「閉じる」
    const onClickCancel = async () => {
      const result = await confirm(
        "キャンセル",
        "これまでの変更を破棄しますか?"
      );
      if (result === true) {
        emit("cancel");
      }
    };

    // 編集内容をキャンセルする「右上×ボタン」
    const cancel = async () => {
      const result = await confirm(
        "キャンセル",
        "これまでの変更を破棄しますか?"
      );
      if (result === true) {
        emit("cancel");
      }
    };

    return {
      form,
      currentContactRef,
      currentContacts,
      postContacts,
      onSubmitSave,
      onClickCancel,
      cancel,
    };
  },
});
</script>
