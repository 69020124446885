<template>
  <div
    ref="appContainerRef"
    id="app-container"
    :style="[
      scroll
        ? {
            height: state.height + 'px',
          }
        : {},
    ]"
    class="flex flex-row p-2 space-x-2"
  >
    <!-- Main -->
    <main
      class="flex flex-col border-gray-200 overflow-y-auto overscroll-none"
      :class="[isAsideOpen ? widthClass : 'w-full']"
    >
      <slot name="main"></slot>
    </main>

    <!-- Side -->
    <aside
      v-if="isAsideOpen"
      class="
        flex-1
        focus:outline-none
        border-l border-gray-300
        overflow-y-auto
        overscroll-none
      "
    >
      <loading v-model:active="state.isLoading" :can-cancel="true" />
      <slot name="aside"> </slot>
    </aside>
  </div>
</template>

<script>
import { defineComponent, reactive, inject, onMounted, watch, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default defineComponent({
  name: "AppContainer",
  components: {
    Loading,
  },
  props: {
    widthClass: { type: String },
    isAsideOpen: { type: Boolean },
    scroll: { type: Boolean, default: true },
  },
  setup() {
    const state = reactive({
      height: 0,
      isLoading: null,
    });
    const appContainerRef = ref(null);

    const window = inject("$window");
    const isLoading = inject("$loading");

    // マウント時の処理、表示データの取得と高さの調整
    onMounted(async () => {
      state.isLoading = isLoading;
      calcHeight();
    });

    watch(window, () => {
      calcHeight();
    });

    watch(isLoading, () => {
      state.isLoading = isLoading;
    });

    const calcHeight = () => {
      state.height =
        window.height - appContainerRef.value.getBoundingClientRect().top - 10;
    };

    return {
      state,
      appContainerRef,
    };
  },
});
</script>
