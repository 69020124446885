import { createApp } from "vue";
import AppConfirm from "../components/common/AppConfirm.vue";

export default async function confirm(title, message) {
  return new Promise((resolve) => {
    const app = createApp(AppConfirm, {
      title: title,
      message: message,
      nextAction: () => {
        resolve(true);
        setTimeout(() => {
          app.unmount();
          const wrapper = document.getElementById("confirm");
          wrapper.remove();
        }, 300);
      },
      cancelAction: () => {
        resolve(false);
        setTimeout(() => {
          app.unmount();
          const wrapper = document.getElementById("confirm");
          wrapper.remove();
        }, 300);
      },
    });

    const wrapper = document.createElement("div");
    wrapper.setAttribute("id", "confirm");
    app.mount(wrapper);
    document.body.appendChild(wrapper);
  }).catch((err) => {
    throw err;
  });
}
