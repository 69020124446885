import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
import { getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

import SignIn from "../views/SignIn.vue";
import Home from "../views/Home.vue";
import ContactList from "../views/ContactList.vue";

const routes = [
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requireAuth: true },
  },
  {
    path: "/manual",
    name: "Manual",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "manual-list" */ "../views/ManualList.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/manual/:docType/:docNo",
    name: "ManualImportList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "manual-import-list" */ "../views/ManualImportList.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/ev",
    name: "ExplodedView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "expanded-view-list" */ "../views/ExplodedViewList.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/ev/:docNo",
    name: "ExplodedViewImportList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "expanded-view-import-list" */ "../views/ExplodedViewImportList.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/error",
    name: "Error",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "error-import-list" */ "../views/ErrorImportList.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/faq",
    name: "Faq",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "help-list" */ "../views/HelpList.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/faq/:domainKey",
    name: "FaqDraft",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "help-draft" */ "../views/HelpDraft.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ContactList,
    meta: { requireAuth: true },
  },
  {
    path: "/master",
    name: "Master",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "master" */ "../views/Master.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/other",
    name: "Other",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "master" */ "../views/Other.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/technical_information",
    name: "Ti",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "master" */ "../views/TechnicalInformation.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/pcm",
    name: "pcm",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "master" */ "../views/pcmViewList.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/lineup_chart",
    name: "LineupChart",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "master" */ "../views/LineupChart.vue"),
    meta: { requireAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function getAuthenticatedUser() {
  return currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInDetails) {
        store.commit("setUser", data);

        return data;
      }
    })
    .catch(() => {
      store.commit("setUser", null);
      return null;
    });
}

//let user;
router.beforeResolve(async (to, from, next) => {
  let user = await getAuthenticatedUser();

  if (!JSON.parse(process.env.VUE_APP_USE_MOCK)) {
    if (to.name === "SignIn" && user) {
      return next({ name: "Home" });
    }

    if (to.matched.some((record) => record.meta.requireAuth) && !user) {
      return next({ name: "SignIn" });
    }
  }

  return next();
});

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signedIn":
      router.push({ name: "Home" });
      break;
    case "signedOut":
      router.push({ name: "SignIn" });
      break;
  }
});

async function currentAuthenticatedUser() {
  try {
    const user = await getCurrentUser();
    return user;
  } catch (err) {
    console.log(err);
  }
}

export default router;
