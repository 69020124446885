<template>
  <!-- Title -->
  <div class="flex mx-2 py-1.5 border-b">
    <h2 class="font-normal text-sm leading-6 text-gray-600">
      <slot></slot>
    </h2>

    <div class="ml-auto pl-3">
      <div class="-mx-1">
        <button
          type="button"
          @click="onClick"
          class="
            inline-flex
            bg-blue-50
            rounded-sm
            p-1.5
            text-blue-500
            hover:bg-blue-100
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-offset-blue-50
            focus:ring-blue-600
          "
        >
          <span class="sr-only">Dismiss</span>
          <XIcon class="h-4 w-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/solid";

export default {
  name: "AppAsideTitle",
  components: {
    XIcon,
  },
  setup(props, context) {
    const onClick = () => {
      context.emit("onClick");
    };

    return {
      onClick,
    };
  },
};
</script>
