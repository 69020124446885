import MockAdapter from "axios-mock-adapter";

export default {
  run: (axios, domain_key) => {
    const mock = new MockAdapter(axios);
    mock.onGet(`/faq/help-domains/${domain_key}/preview`).reply(200, _helpDomain_preview);
    mock.onGet("/faq/help-domains").reply(200, _helpDomains);
    mock.onPost("/faq/help-domains").reply(200, _helpDomains_add);
    mock.onPut("/faq/help-domains").reply(200, _helpDomains_sort);
    mock.onPut("/faq/help-domains/e-tube").reply(200, _helpDomain_update);
    mock
      .onPost("/faq/help-domains/e-tube/preview")
      .reply(200, _helpDomain_publish);
    mock
      .onPost("/faq/help-domains/e-tube/publish")
      .reply(200, _helpDomain_publish);
    mock
      .onPost("/faq/help-domains/e-tube/stop")
      .reply(200, _helpDomain_publish);
  },
};

const _helpDomain_publish = {
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
};

const _helpDomain_update = {
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data: [
    {
      domain_key: "e-tube",
      name: "E-Tube",
      index: 0,
      title: "FAQ - Shimano    STEPS",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: 1,
      publish_date: "2021-09-08",
      public_locales: ["en-US", "ja-JP", "es-AR"],
    },
    {
      domain_key: "connect-tracker",
      name: "Connect Tracker",
      index: 1,
      title: "FAQ - Shimano Connect Tracker",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: null,
      publish_date: null,
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "common",
      name: "Common",
      public_locales: ["en-US", "ja-JP"],
    },
  ],
};

const _helpDomains = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data: [
    {
      domain_key: "e-tube",
      name: "E-Tube",
      index: 0,
      title: "FAQ - Shimano STEPS",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: 1,
      publish_date: "2021-09-08",
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "connect-tracker",
      name: "Connect Tracker",
      index: 1,
      title: "FAQ - Shimano Connect Tracker",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: null,
      publish_date: null,
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "common",
      name: "Common",
      public_locales: ["en-US", "ja-JP"],
    },
  ],
});

const _helpDomains_add = {
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data: [
    {
      domain_key: "e-tube",
      name: "E-Tube",
      index: 0,
      title: "FAQ - Shimano STEPS",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: 1,
      publish_date: "2021-09-08",
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "connect-tracker",
      name: "Connect Tracker",
      index: 1,
      title: "FAQ - Shimano Connect Tracker",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: null,
      publish_date: null,
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "new",
      name: "New",
      index: 2,
      title: "FAQ - Shimano New Product",
      description: null,
      title_ja: null,
      description_ja: null,
      public_revision: null,
      publish_date: null,
      public_locales: null,
    },
    {
      domain_key: "common",
      name: "Common",
      public_locales: ["en-US", "ja-JP"],
    },
  ],
};

const _helpDomains_sort = {
  data: [
    {
      domain_key: "connect-tracker",
      name: "Connect Tracker",
      index: 1,
      title: "FAQ - Shimano Connect Tracker",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: null,
      publish_date: null,
      public_locales: ["en-US", "ja-JP"],
    },
    {
      domain_key: "e-tube",
      name: "E-Tube",
      index: 0,
      title: "FAQ - Shimano STEPS",
      description:
        "Find answers to the most commonly asked questions about Shimano STEPS.",
      title_ja: "FAQ - シマノ STEPS",
      description_ja: "Shimano STEPS の よくある質問集です。",
      public_revision: 1,
      publish_date: "2021-09-08",
      public_locales: ["en-US", "ja-JP"],
    },
  ],
};

const _helpDomain_preview = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK"
  },
  data: {
    previews: [
      {
        public_locale: "en-US",
        revision: "2",
        created_at: "2021-11-10T09:00:00+09:00"
      },
      {
        public_locale: "ja-JP",
        revision: "2",
        created_at: "2021-11-10T09:00:00+09:00"
      },
      {
        public_locale: "es-ES",
        revision: "1",
        created_at: "2021-11-10T09:00:00+09:00"
      }
    ]
  }
});
