<template>
  <div id="contact-lists" class="flex flex-col h-screen overflow-y-hidden">
    <!-- Header -->
    <app-header></app-header>

    <!-- Page Title -->
    <app-title>
      <template v-slot:title> 問い合わせ履歴 </template>
    </app-title>

    <!-- Action button -->
    <div class="flex min-w-0 p-3 bg-gray-100">
      <span class="inline-flex space-x-2">
        <app-button @onClick="changeContactAdmin">
          <template v-slot:label> メール送信先の変更 </template>
          <template v-slot:icon>
            <UserAddIcon class="h-6 w-6" />
          </template>
        </app-button>
      </span>
    </div>

    <!-- Container -->
    <app-container :widthClass="'w-0'" :isAsideOpen="state.isAsideOpen">
      <!-- Main Container -->
      <template v-slot:main>
        <!-- Table -->
        <wj-flex-grid
          :autoGenerateColumns="false"
          :itemsSource="state.contacts"
          :isReadOnly="true"
        >
          <wj-flex-grid-column
            header="ID"
            binding="id"
            width="*"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            header="問い合せ内容"
            binding="description"
            width="10*"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            header="送信元"
            binding="send_email[0]"
            width="4*"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            header="送信日"
            binding="created_at"
            width="4*"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            header="送信先"
            binding="send_email[1]"
            width="4*"
          ></wj-flex-grid-column>
        </wj-flex-grid>
      </template>

      <!-- Aside Container -->
      <template v-slot:aside>
        <app-contact-admin-change
          @cancel="postCancel"
          @save="postSave"
        ></app-contact-admin-change>
      </template>
    </app-container>
  </div>
</template>

<script>
// vue
import { defineComponent, reactive, onMounted } from "vue";

// heroicons
import { UserAddIcon } from "@heroicons/vue/outline";

// wijmo
import { WjFlexGrid, WjFlexGridColumn } from "@grapecity/wijmo.vue2.grid";

// app
import AppHeader from "../components/layout/AppHeader.vue";
import AppTitle from "../components/layout/AppTitle.vue";
import AppContainer from "../components/layout/AppContainer.vue";
import AppButton from "../components/common/AppButton.vue";
import AppContactAdminChange from "../components/contact/ContactAdminChange.vue";
import notify from "../api/notify";

// app
import ContactsService from "../services/contactsService.js";

export default defineComponent({
  name: "ContactList",
  components: {
    UserAddIcon,
    WjFlexGrid,
    WjFlexGridColumn,
    AppHeader,
    AppTitle,
    AppContainer,
    AppButton,
    AppContactAdminChange,
  },
  setup() {
    const state = reactive({
      isAsideOpen: false,
      contacts: [],
    });

    const {
      contactsreview,
      getContacts,
    } = ContactsService()

    // マウント時の処理、表示データの取得
    onMounted(async () => {
      await getContacts();
      state.contacts = contactsreview.value
    });

    // ....
    const changeContactAdmin = () => {
      state.isAsideOpen = true;
    };

    // エディタの保存イベント:エディタで保存の後処理を行う
    const postSave = async () => {
      state.isAsideOpen = false;
      await notify("保存が完了しました.");
    };

    // エディタのキャンセルイベント:エディタでキャンセルを押した後に後処理を行う
    const postCancel = () => {
      state.isAsideOpen = false;
    };

    return {
      state,
      changeContactAdmin,
      postSave,
      postCancel,
      getContacts,
      contactsreview,
    };
  },
  data() {
    return {
      contacts: [
        {
          id: "1",
          description:
            "There is something I want to know about E-TUBE PROJECT.",
          created_user: "taro_yamada@google.co.jp",
          created_at: "2011/11/10 9:00",
          received_user: "hanako_sato@shimano.co.jp",
        },
      ],
    };
  },
});
</script>
