import MockAdapter from "axios-mock-adapter";

export default {
  run: (axios) => {
    const mock = new MockAdapter(axios);
    mock.onGet("/faq/contacts").reply(200, _contacts);
    mock.onGet("/faq/admin-mail").reply(200, _contacts_address);
  },
};

const _contacts = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data:  [
    {
      id: 1,
      description: "問い合わせのテストです。XXXXがYYYYでしょうか？",
      created_at: "2021-11-12T21:00:00+09:00",
      send_email:[
        "admin001@admin.test",
        "admin002@admin.test"
      ]
    },
    {
      id: 2,
      description: "問い合わせのテストです。XXXXがYYYYでしょうか？",
      created_at: "2021-11-12T21:00:00+09:00",
      send_email:[
        "admin003@admin.test",
        "admin004@admin.test"
      ]
    },
  ]
});

const _contacts_address = JSON.stringify({
  email:  [
    "admin001@admin.test",
    "admin002@admin.test"
  ]
});