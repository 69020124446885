import Axios from "axios";
import mock from "../mocks/contactsService";
import { fetchAuthSession } from "aws-amplify/auth";
import { ref } from "vue";

const axios = Axios.create({
  baseURL:
    process.env.VUE_APP_API_ENDPOINT || "http://127.0.0.1:3000/api/admin/v1",
});

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${(
    await fetchAuthSession()
  ).tokens.idToken.toString()}`;

  return config;
});

if (
  process.env.VUE_APP_USE_MOCK === undefined ||
  JSON.parse(process.env.VUE_APP_USE_MOCK)
) {
  mock.run(axios);
}

export default function () {
  const contactsreview = ref([]);

  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
  const dateTimeFormat =
    /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2}$)/;

  // ISO 8601 フォーマットをDateオブジェクトに変換する
  const dateTimeReviver = (key, value) => {
    if (
      typeof value === "string" &&
      (key === "public_locale" || key === "revision") &&
      dateFormat.test(value)
    ) {
      return new Date(value);
    } else if (
      typeof value === "string" &&
      key === "created_at" &&
      dateTimeFormat.test(value)
    ) {
      return new Date(value);
    }

    return value;
  };

  // api :admin-v1-faq-contacts-get
  async function getContacts() {
    const response = await axios.get("/faq/contacts", {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
    contactsreview.value = response.data.data;
  }

  // api :admin-v1-faq-admin-mail-get
  const currentContactRef = ref();
  async function currentContacts() {
    const response = await axios.get("/faq/admin-mail", {
      transformResponse: (data) => {
        return JSON.parse(data, dateTimeReviver);
      },
    });
    currentContactRef.value = response.data.email[0];
  }

  // api :admin-v1-faq-admin-mail-post
  async function postContacts(email) {
    return await axios
      .post(
        "/faq/admin-mail",
        {
          email: email,
        },
        {
          transformResponse: (data) => {
            return JSON.parse(data, dateTimeReviver);
          },
        }
      )
      .then((response) => {
        return {
          success: response.data.success,
          message: response.data.meta.messege,
          data: response.data.email[0],
        };
      })
      .catch((error) => {
        return {
          success: error.response.data.success,
          message: error.response.data.meta.message,
        };
      });
  }

  return {
    contactsreview,
    currentContactRef,
    getContacts,
    currentContacts,
    postContacts,
  };
}
