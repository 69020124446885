import { createStore } from "vuex";
import helpDomainService from "../services/helpDomainService";
import manualService from "../services/manualService";
import explodedViewService from "../services/explodedViewService";

export default createStore({
  state: {
    user: null,
    helpDomains: [],
    helpCommon: null,
    manuals: [],
    explodedViews: [],
  },
  getters: {
    currentHelpDomain: (state) => (domainKey) => {
      return state.helpDomains.find((item) => item.domain_key === domainKey);
    },
  },
  actions: {
    async getHelpDomains({ commit }) {
      const { getHelpDomains } = helpDomainService();
      await getHelpDomains().then((response) => {
        commit("setHelpDomains", response.data.data);
      });
    },
    createHelpDomain({ commit }, helpDomain) {
      const { createHelpDomain } = helpDomainService();
      return createHelpDomain(helpDomain)
        .then((response) => {
          commit("setHelpDomains", response.data.data);
          return {
            success: response.data.success,
            message: response.data.meta.messege,
          };
        })
        .catch((error) => {
          return {
            success: error.response.data.success,
            message: error.response.data.meta.message,
          };
        });
    },
    sortHelpDomain({ commit }, helpDomain) {
      const { sortHelpDomain } = helpDomainService();
      return sortHelpDomain(helpDomain)
        .then((response) => {
          commit("setHelpDomains", response.data.data);
          return {
            success: response.data.success,
            message: response.data.meta.messege,
          };
        })
        .catch((error) => {
          return {
            success: error.response.data.success,
            message: error.response.data.meta.message,
          };
        });
    },
    updateHelpDomain({ commit }, { domainKey, helpDomain }) {
      const { updateHelpDomain } = helpDomainService();
      return updateHelpDomain(domainKey, helpDomain)
        .then((response) => {
          commit("setHelpDomains", response.data.data);
          return {
            success: response.data.success,
            message: response.data.meta.messege,
          };
        })
        .catch((error) => {
          return {
            success: error.response.data.success,
            message: error.response.data.meta.message,
          };
        });
    },
    async getManuals({ commit }) {
      const { getManuals } = manualService();
      await getManuals().then((response) => {
        commit("setManuals", response.data.data);
      });
    },
    async getExplodedViews({ commit }) {
      const { getExplodedViews } = explodedViewService();
      await getExplodedViews().then((response) => {
        commit("setExplodedViews", response.data.data);
      });
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    updateHelpDomain(state, { domainKey, helpDomain }) {
      const currentHelpDomain = state.helpDomains.find(
        (item) => item.domain_key === domainKey
      );
      Object.keys(helpDomain).forEach((key) => {
        if (key !== "domain_key") currentHelpDomain[key] = helpDomain[key];
      });
    },
    setHelpDomains(state, helpDomains) {
      state.helpCommon = helpDomains.find(
        (item) => item.domain_key === "common"
      );
      state.helpDomains = helpDomains.filter(
        (item) => item.domain_key !== "common"
      );
    },
    setManuals(state, data) {
      state.manuals = data.manuals;
    },
    setExplodedViews(state, data) {
      state.explodedViews = data.manuals;
    },
  },
  modules: {},
});
