<template>
  <div id="help-lists" class="flex flex-col h-screen overflow-y-hidden">
    <!-- Header -->
    <app-header></app-header>

    <!-- Page Title -->
    <app-title>
      <template v-slot:title> ホーム </template>
    </app-title>

    <!-- Page Panel -->
    <div class="flex flex-row p-2 space-x-2">
      <div class="w-full pb-2">
        <a
          target="_blank"
          rel="noopener noreferrer"
          :href="`/product_preview/en`"
        >
          <span class="cursor-pointer">技術資料のプレビュー表示</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// vue
import { defineComponent, reactive } from "vue";

// app
import AppHeader from "../components/layout/AppHeader.vue";
import AppTitle from "../components/layout/AppTitle.vue";

export default defineComponent({
  name: "Home",
  components: {
    AppHeader,
    AppTitle,
  },
  setup() {
    const state = reactive({
      isAsideOpen: false,
    });

    return {
      state,
    };
  },
});
</script>
