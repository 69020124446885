<template>
  <button
    :type="type"
    @click="onClick"
    class="
      relative
      inline-flex
      items-center
      px-3
      py-1
      border-solid border border-gray-300
      bg-white
      text-sm
      font-medium
      rounded-sm
      text-gray-900
      hover:bg-gray-50
      focus:z-10
      focus:outline-none
      focus:ring-1
      focus:ring-blue-600
      focus:border-blue-600
    "
  >
    <svg
      v-if="state.isLoading"
      class="animate-spin mr-1 h-5 w-5 text-blue-800"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <slot name="icon" v-if="!state.isLoading"></slot>
    <span><slot name="label"></slot></span>
  </button>
</template>

<script>
import { defineComponent, watch, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "button",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const state = reactive({
      isLoading: false,
    });

    const { isLoading } = toRefs(props);

    watch(isLoading, () => {
      if (isLoading.value) {
        state.isLoading = isLoading.value;
      } else {
        setTimeout(() => (state.isLoading = isLoading.value), 2000);
      }
    });

    const onClick = () => {
      context.emit("onClick");
    };

    return {
      state,
      onClick,
    };
  },
});
</script>
