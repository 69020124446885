import { reactive, toRefs } from "vue";

const WindowSizePlugin = {
  install: (app) => {
    const $window = reactive({
      width: 0,
      height: 0,
    });

    function onResize() {
      let { width, height } = toRefs($window);
      width.value = document.documentElement.clientWidth;
      height.value = document.documentElement.clientHeight;
    }

    window.addEventListener("resize", onResize);
    onResize();

    app.provide("$window", $window);
    app.config.globalProperties.$window = $window;
  },
};

export default WindowSizePlugin;
