<template>
  <div class="flex justify-between h-12 bg-white border">
    <div class="flex px-4 py-2">
      <div class="flex-shrink-0 flex items-center">
        <a href="#">
          <img class="h-4 w-auto" src="@/assets/logo-blue.webp" alt="logo" />
        </a>
      </div>
      <nav aria-label="Global" class="ml-6 flex items-center space-x-4">
        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/manual"
        >
          マニュアル
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/ev"
        >
          EV
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/other"
        >
          EV(ロケール非公開)
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/error"
        >
          エラーコード
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/faq"
        >
          FAQ
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/master"
        >
          マスタ
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/technical_information"
        >
          Technical information
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/lineup_chart"
        >
          Lineup chart
        </router-link>

        <router-link
          class="px-3 py-2 text-gray-900 text-sm font-medium"
          to="/pcm"
        >
          pcm連携
        </router-link>
      </nav>
    </div>

    <!-- header end -->
    <div class="flex-1 flex items-center px-2 ml-6 h-10 justify-end">
      <a
        href="#"
        @click.prevent.stop="onClickSignOut"
        class="px-3 py-2 text-gray-900 text-sm font-medium"
      >
        ログアウト
      </a>
    </div>
  </div>
</template>

<script>
import { signOut } from "aws-amplify/auth";

export default {
  name: "AppHeader",
  setup() {
    const onClickSignOut = () => {
      signOut().catch((error) => console.log("サインアウト失敗: ", error));
      window.location.href = `https://${process.env.VUE_APP_COOKIE_DOMAIN}/logout`;
    };
    return {
      onClickSignOut,
    };
  },
};
</script>
