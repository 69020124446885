<template>
  <div
    class="
      flex
      items-center
      justify-between
      h-14
      py-2
      px-4
      border-b border-gray-200
    "
  >
    <span class="flex flex-col">
      <h1
        class="text-left text-lg font-medium leading-6 text-gray-900 truncate"
      >
        <slot name="title"></slot>
      </h1>
      <span class="relative inline-flex items-center px-2">
        <slot name="description"></slot>
      </span>
    </span>

    <span class="justify-end space-x-2">
      <slot name="action"></slot>
    </span>
  </div>
</template>
<script>
export default {
  name: "AppTitle",
};
</script>
