import MockAdapter from "axios-mock-adapter";

export default {
  run: (axios, doc_type, doc_no) => {
    const mock = new MockAdapter(axios);
    mock.onGet("/man/manuals").reply(200, _manuals);
    mock.onGet(`/man/manuals/${doc_type}/${doc_no}/preview`).reply(200, _manuals_preview);
  },
};

const _manuals = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK",
  },
  data: {
    manuals: [
      {
        doc_type: "dm",
        doc_no: "madbr01",
        name: "Hyrraulic Disc Brake",
        status: "公開中",
        publish_date: "2020-09-08",
        next_publish_date: "2021-09-08",
        next_status: "インポート済み",
      },
      {
        doc_type: "um",
        doc_no: "7j4wa",
        name: "E-TUBE PROJECT Professional",
        status: "公開中",
        publish_date: "2021-09-01",
        public_revision: 1,
      },
      {
        doc_type: "um",
        doc_no: "5xw0c",
        name: "Rear Derailler (Di2)",
        status: "公開中",
        publish_date: "2021-04-24",
        public_revision: 1,
      },
      {
        doc_type: "um",
        doc_no: "3gk0a",
        name: "Rear Derailler (Di2) / Battery Charger",
        status: "公開中",
        publish_date: "2021-09-01",
        public_revision: 1,
      },
      {
        doc_type: "dm",
        doc_no: "mbcs001",
        name: "Cassette Sprocket",
        status: "公開中",
        publish_date: "2021-05-31",
        public_revision: 1,
      },
      {
        doc_type: "dm",
        doc_no: "mawh002",
        name: "Wheel Set (Disc Brake)",
        status: "公開中",
        publish_date: "2021-05-31",
        public_revision: 1,
      },
    ],
  },
});

const _manuals_preview = JSON.stringify({
  success: true,
  meta: {
    code: 200,
    message: "OK"
  },
  data: {
    previews: [
      {
        public_locale: "en",
        revision: "05",
        pdf_file_path: "DM-MADBR01-05-ENG.pdf",
        created_at: "2021-11-10T09:00:00+09:00"
      },
      {
        public_locale: "ja",
        revision: "05",
        pdf_file_path: "DM-MADBR01-05-JPN.pdf",
        created_at: "2021-11-10T09:00:00+09:00"
      },
      {
        public_locale: "de",
        revision: "04",
        pdf_file_path: "DM-MADBR01-04-GER.pdf",
        created_at: "2021-11-10T09:00:00+09:00"
      }
    ]
  }
});
